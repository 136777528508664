import React, {
  useEffect,
  useMemo,
  useState,
  ChangeEvent,
  ComponentProps,
} from 'react';

import { EInputVariant, Input } from '../Input';
import {
  ESelectBackgroundVariant,
  ESelectVariant,
  Option,
  Select,
} from '../Select';

const SOCIALS = [
  {
    label: 'WhatsApp',
    value: 'whatsapp',
  },
  {
    label: 'Telegram',
    value: 'telegram',
  },
];

export type TSocialInputProps = {
  onChange: (value: string) => void;
  required?: boolean;
} & Partial<ComponentProps<typeof Input>>;

const SocialInput: React.FC<TSocialInputProps> = props => {
  const { value, onChange, variant, ...restInputProps } = props;

  const [currentDropdownIdx, setCurrentDropdownIdx] = useState<number | null>(
    0,
  );
  const [currentValue, setCurrentValue] = useState(value || '');

  const Options = useMemo(
    () =>
      SOCIALS.map((s, i) => (
        <Option key={i} label={s.label}>
          <span className='whitespace-nowrap'>{s.label}</span>
        </Option>
      )),
    [],
  );

  useEffect(() => {
    if (!value && currentValue) {
      // support reset
      setCurrentDropdownIdx(0);
      setCurrentValue('');
    }
  }, [value, currentValue]);

  const onChangeCurrentIndex = (newIndex: number | null): void => {
    setCurrentDropdownIdx(newIndex);
    onChange(
      currentDropdownIdx !== null && currentValue
        ? `${SOCIALS[currentDropdownIdx].value} ${currentValue}`
        : '',
    );
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.target.value || '';
    setCurrentValue(newValue);
    onChange(
      currentDropdownIdx !== null && newValue
        ? `${SOCIALS[currentDropdownIdx].value} ${newValue}`
        : '',
    );
  };

  const selectBackgroundVariant =
    variant === EInputVariant.Primary
      ? ESelectBackgroundVariant.Primary
      : ESelectBackgroundVariant.Secondary;

  return (
    <div className='flex gap-2'>
      <div className='relative box-border w-[7.75rem]'>
        <Select
          variant={ESelectVariant.Input}
          backgroundVariant={selectBackgroundVariant}
          label='Social Media'
          value={currentDropdownIdx}
          onChange={onChangeCurrentIndex}
          options={SOCIALS}
        >
          {Options}
        </Select>
      </div>
      <div className='flex-grow'>
        <Input
          {...restInputProps}
          fullWidth
          variant={variant || EInputVariant.Secondary}
          placeholder='Nickname'
          value={currentValue}
          onChange={onChangeInput}
        />
      </div>
    </div>
  );
};

export default SocialInput;
