import clsx from 'clsx';
import React, { ReactNode } from 'react';

import {
  ERowSpacing,
  ESectionBackground,
  ESectionSpacing,
  ESectionVariant,
  ETextColor,
} from './ContentSection.constants';
import { type TAction } from '../../../types';
import { RichText, TRichTextContent } from '../../atoms/RichText';
import isRichTextContentEmpty from '../../atoms/RichText/utils/checkEmptyRichText';
import { SectionTitle } from '../../atoms/SectionTitle/SectionTitle';
import {
  ESectionTitleAlignContent,
  ESectionTitleContentWidth,
  ESectionTitleTheme,
  ESectionTitleType,
  ETitleSpacing,
} from '../../atoms/SectionTitle/SectionTitle.constants';
import {
  Container,
  EContainerBackground,
  EContainerMobileRadius,
  EContainerVariant,
} from '../Container';

type TEeyebrows = {
  text: string;
  id?: string;
}[];

export interface IContentSectionProps {
  index?: number;
  /**
   * The section content. Each child will be placed on a new row.
   */
  children: ReactNode | ReactNode[];
  /**
   * The section title.
   */
  title?: string;
  /**
   * The section subtitle.
   */
  subtitle?: string;
  /**
   * Move subtitle above the title.
   */
  isSubtitleTop?: boolean;
  /**
   * The vertical spacing between the ContentSection and surrounding elements.
   * This spacing controls the distance between the ContentSection and other components on the page.
   */
  spacingY?: ESectionSpacing;
  /**
   * The vertical spacing between the title and the content within the section.
   * This spacing determines the distance between the title and the children elements (section rows).
   */
  titleSpacingY?: ETitleSpacing;
  /**
   * The vertical spacing between rows within the content of the ContentSection.
   * This spacing determines the distance between the children elements (section rows).
   */
  rowSpacingY?: ERowSpacing;
  /**
   * A short section title used to describe the purpose of the section.
   */
  topDescription?: TRichTextContent;
  /**
   * Deprecated.
   */
  description?: string;
  /**
   * A short section title used to describe the purpose of the section. (at the bottom of the section).
   */
  bottomDescription?: {
    [k: string]: unknown;
  }[];
  /**
   * Background for the whole section.
   */
  background?: ESectionBackground;
  /**
   * Background color for the whole section.
   */
  backgroundColor?: string;
  /**
   * Round section.
   */
  isRounded?: boolean;
  /**
   * Size container.
   */
  containerVariant?: EContainerVariant;
  /**
   * Background for container.
   */
  containerBackground?: EContainerBackground;
  /**
   * Round container.
   */
  isContainerRounded?: boolean;

  containerRadiusOnMobile?: EContainerMobileRadius;
  /**
   * Make container full width on mobile.
   */
  isNoPaddingOnMobile?: boolean;
  /**
   * Align text horizontally.
   */
  titleTextAlign?: ESectionTitleAlignContent;
  /**
   * Text color.
   */
  textColor?: ETextColor;
  id?: string;
  dataGaId?: string;
  actions?: TAction[];
  sectionVariant?: ESectionVariant;
  animationRef?: React.Ref<HTMLDivElement>;
  animationClasses?: string;
  titleType?: ESectionTitleType;
  themeTitle?: ESectionTitleTheme;
  titleContentWidth?: ESectionTitleContentWidth;
  eyebrowsTop?: TEeyebrows;
  eyebrowsBottom?: TEeyebrows;
}

const ContentSection: React.FC<IContentSectionProps> = props => {
  const {
    id,
    title,
    subtitle,
    isSubtitleTop,
    description,
    bottomDescription,
    topDescription,
    actions,
    spacingY = ESectionSpacing.Xl,
    titleSpacingY = ETitleSpacing.Lg,
    rowSpacingY = ERowSpacing.Base,
    background = ESectionBackground.White,
    titleTextAlign = ESectionTitleAlignContent.Center,
    textColor = ETextColor.Dark,
    isRounded = true,
    containerVariant = EContainerVariant.Lead,
    sectionVariant,
    containerBackground = EContainerBackground.Transparent,
    isContainerRounded = true,
    containerRadiusOnMobile,
    isNoPaddingOnMobile,
    children,
    dataGaId,
    animationRef,
    animationClasses,
    backgroundColor,
    titleType,
    titleContentWidth,
    themeTitle,
    eyebrowsTop,
    eyebrowsBottom,
  } = props;
  const isValidChildren =
    children && (!Array.isArray(children) || !!children.length);
  let sectionHeadElementClasses = 'lg:max-w-[38.75rem]';
  let finalContainerVariant = containerVariant;

  const hasTitleMargin =
    isValidChildren ||
    (bottomDescription && !isRichTextContentEmpty(bottomDescription));

  //@TODO: After changing the section configs from the b2broker.com website and joining the main size from the section,  need to remove this state code and pass the containerVariant instead of finalContainerVariant.
  if (sectionVariant === ESectionVariant.B2Broker) {
    sectionHeadElementClasses = 'lg:max-w-[64rem]';

    finalContainerVariant =
      containerVariant === EContainerVariant.Full
        ? containerVariant
        : EContainerVariant.Main;
  }
  const hasSectionPadding = background !== ESectionBackground.Transparent;

  const hasContainerPadding =
    containerBackground !== EContainerBackground.Transparent &&
    background.toString() !== containerBackground.toString();

  let themedTextStyles = '';
  let themedSectionStyles = '';
  if (background === ESectionBackground.Theme4) {
    themedSectionStyles = 'bg-surface-1000';
    themedTextStyles = 'text-interface-50/80';
  }

  const isLightText =
    textColor === ETextColor.Light ||
    containerBackground === EContainerBackground.Dark ||
    (containerBackground === EContainerBackground.Transparent &&
      background === ESectionBackground.Dark);

  let sectionTitleType = titleType;
  if (!sectionTitleType) {
    sectionTitleType =
      sectionVariant === ESectionVariant.B2Broker
        ? ESectionTitleType.B
        : ESectionTitleType.A;
  }
  const eyebrowsAbove = (eyebrowsTop || []).map(eyebrow => eyebrow.text);
  const eyebrowsBelow = (eyebrowsBottom || []).map(eyebrow => eyebrow.text);
  if (subtitle) {
    if (isSubtitleTop) {
      eyebrowsAbove.push(subtitle);
    } else {
      eyebrowsBelow.push(subtitle);
    }
  }
  let sectionTitleTheme = themeTitle;
  if (!sectionTitleTheme) {
    sectionTitleTheme =
      background === ESectionBackground.Theme4
        ? ESectionTitleTheme.SectionTitle4
        : isLightText
          ? ESectionTitleTheme.SectionTitle3
          : ESectionTitleTheme.SectionTitle1;
  }
  let sectionTitleContentWidth = titleContentWidth;
  if (!titleContentWidth) {
    sectionTitleContentWidth =
      sectionVariant === ESectionVariant.B2Broker
        ? ESectionTitleContentWidth.Xl
        : ESectionTitleContentWidth.Md;
  }

  return (
    <section
      id={id}
      className={clsx('scroll-mt-24', themedSectionStyles, {
        'rounded-3xl lg:rounded-[2rem]': isRounded,
        'py-4': hasSectionPadding && spacingY === ESectionSpacing.Xs,
        'py-8': hasSectionPadding && spacingY === ESectionSpacing.Sm,
        'py-12 md:py-16 2xl:py-[5.5rem]':
          hasSectionPadding && spacingY === ESectionSpacing.XxL,
        'py-8 2xl:py-16':
          hasSectionPadding && spacingY === ESectionSpacing.Base,
        'py-12 2xl:py-16': hasSectionPadding && spacingY === ESectionSpacing.Xl,
        'bg-surface-50':
          background === ESectionBackground.White && !backgroundColor,
        'bg-surface-100':
          background === ESectionBackground.Light && !backgroundColor,
        'bg-black': background === ESectionBackground.Dark && !backgroundColor,
      })}
      style={
        backgroundColor
          ? {
              backgroundColor,
            }
          : {}
      }
      data-ga-id={dataGaId}
    >
      <Container
        variant={finalContainerVariant}
        containerBackground={containerBackground}
        isContainerRounded={isContainerRounded}
        containerRadiusOnMobile={containerRadiusOnMobile}
        isPadding={hasSectionPadding && hasContainerPadding}
        isNoPaddingOnMobile={isNoPaddingOnMobile}
      >
        <div
          ref={animationRef}
          className={clsx({
            'py-8 2xl:py-16':
              hasContainerPadding && spacingY === ESectionSpacing.Base,
            'py-12 2xl:py-16':
              hasContainerPadding && spacingY === ESectionSpacing.Xl,
          })}
        >
          <SectionTitle
            eyebrowsTop={eyebrowsAbove}
            title={title}
            topDescription={topDescription}
            eyebrowsBottom={eyebrowsBelow}
            description={description}
            buttons={actions}
            titleType={sectionTitleType}
            titleAlignContent={titleTextAlign}
            themeTitle={sectionTitleTheme}
            titleContentWidth={sectionTitleContentWidth}
            animationClasses={animationClasses}
            titleSpacingY={titleSpacingY}
            hasTitleMargin={hasTitleMargin}
          />
          {isValidChildren && (
            <div
              className={clsx('flex w-full flex-col', {
                'gap-4': rowSpacingY === ERowSpacing.Xs,
                'gap-4 2xl:gap-8': rowSpacingY === ERowSpacing.Base,
                'gap-6 2xl:gap-8': rowSpacingY === ERowSpacing.Md,
                'gap-10 2xl:gap-12': rowSpacingY === ERowSpacing.Lg,
                'gap-12 2xl:gap-16': rowSpacingY === ERowSpacing.Xl,
              })}
            >
              {children}
            </div>
          )}

          {bottomDescription && !isRichTextContentEmpty(bottomDescription) && (
            <div
              className={clsx(
                'mx-auto px-4 text-center empty:hidden 2xl:px-0',
                sectionHeadElementClasses,
              )}
            >
              <span
                className={clsx(
                  'mt-8 block text-base font-normal empty:hidden',
                  themedTextStyles,
                  {
                    'text-interface-800': !isLightText && !themedTextStyles,
                    'text-interface-50/70': isLightText && !themedTextStyles,
                  },
                )}
              >
                <RichText content={bottomDescription} />
              </span>
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

export default ContentSection;
