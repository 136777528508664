export const enum ESelectVariant {
  Input = 'input',
  Menu = 'menu',
  Widget = 'widget',
}

export const enum ESelectBackgroundVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Widget = 'widget',
}
