import { baseTailwindConfig } from '@front/shared';
import { Container } from '@front/shared/ds';
import React, { useState, useMemo } from 'react';
import { useMedia } from 'react-use';

import { HeroAnimatedSliderTitle } from './HeroAnimatedSliderTitle';
import { MediaSlides } from './MediaSlides';
import { QrAnimation } from './QrAnimation';
import { Slider } from './Slider';

import type { TSliderCard } from './types';

type THeroAnimatedSliderProps = {
  sliderCards: TSliderCard[];
  isShowSlider: boolean;
};

export const HeroAnimatedSlider: React.FC<THeroAnimatedSliderProps> = ({
  sliderCards,
  isShowSlider,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const isXlBreakpoint = useMedia(
    `(min-width: ${baseTailwindConfig.screens.xl})`,
    true,
  );

  const mediaSlides = useMemo(
    () =>
      sliderCards.map(card => ({
        image: card.image,
        video: card.video,
        hasMask: card.isVisualMaskLayer,
      })),
    [sliderCards],
  );

  const activeSlide = sliderCards?.[activeIndex];

  if (!sliderCards?.length) {
    return null;
  }

  if (!activeSlide) {
    return null;
  }

  const onClickSlide = (index: number): void => {
    setActiveIndex(index);
  };

  return (
    <div className='relative overflow-hidden pb-[72px] pt-[128px]'>
      <MediaSlides activeIndex={activeIndex} slides={mediaSlides} />

      <div className='relative z-10'>
        <Container>
          <div className='flex flex-row justify-between gap-4'>
            <div className='flex min-w-0 flex-1 flex-col justify-between gap-[48px]'>
              <HeroAnimatedSliderTitle
                key={activeIndex}
                activeSlide={activeSlide}
                isXlBreakpoint={isXlBreakpoint}
              />

              {isShowSlider ? (
                <div className='md:-mx-8'>
                  <Slider
                    sliderCards={sliderCards.map(slide => ({
                      title: slide.sliderTitle,
                      icon: slide.sliderIcon,
                    }))}
                    activeIndex={activeIndex}
                    onClickSlide={onClickSlide}
                  />
                </div>
              ) : null}
            </div>

            {activeSlide.animation?.type === 'qr' ? (
              <div className='hidden xl:block'>
                <QrAnimation
                  key={activeIndex}
                  {...activeSlide.animation.data}
                />
              </div>
            ) : null}
          </div>
        </Container>
      </div>
    </div>
  );
};
