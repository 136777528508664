import Head from 'next/head';
import React, { FC } from 'react';

import type { Page } from '@shared/master-types';

export type TThemeProps = { theme: Required<Page>['parts']['theme'] };

const Theme: FC<TThemeProps> = props => {
  const { theme } = props;

  if (!theme || typeof theme === 'string' || !theme?.colors?.length) {
    return null;
  }

  const colors: string = theme?.colors
    ?.map(({ colorName, color }) => `${colorName}: ${color};`)
    ?.join('\n');

  const colorsStyle = `#_app {${colors}}`;

  return (
    <Head>
      <style>{colorsStyle}</style>
    </Head>
  );
};

export default Theme;
