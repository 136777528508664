import { ImageWithPlaceholder, useScramble } from '@front/shared/ds';
import React from 'react';

import { scrambleSettings } from './constants';

import type { TQrAnimationProps } from './types';

export const QrAnimation: React.FC<TQrAnimationProps> = ({
  order,
  price,
  type,
  qrImage,
  qrDescription,
  currenciesImage,
  currenciesText,
}) => {
  const orderWords = (order || '').split('#');

  const { ref: priceRef } = useScramble({
    text: price,
    ...scrambleSettings,
  });

  const { ref: orderNumberRef } = useScramble({
    text: orderWords[1],
    ...scrambleSettings,
  });

  const { ref: qrDescriptionRef } = useScramble({
    text: qrDescription,
    ...scrambleSettings,
  });

  return (
    <div className='flex h-[504px] w-[504px] flex-col justify-between rounded-4xl p-6 text-white backdrop-blur-md'>
      <div className='flex flex-col gap-14'>
        <div className='flex flex-row justify-between'>
          <div className='flex flex-col gap-3'>
            <div className='text-lg font-semibold leading-extra-loose'>
              {orderWords[0]}
              {orderWords[1] ? (
                <span>
                  #<span ref={orderNumberRef}>{orderWords[1]}</span>
                </span>
              ) : null}
            </div>
            <div className='text-6xl font-semibold leading-relaxed'>
              <span ref={priceRef}>{price}</span>
            </div>
          </div>

          {type ? (
            <div className='text-lg font-semibold uppercase leading-extra-loose text-white/25'>
              {type}
            </div>
          ) : null}
        </div>
        <div className='flex flex-col items-center gap-3'>
          {qrImage ? (
            <div>
              <ImageWithPlaceholder {...qrImage} loading='lazy' />
            </div>
          ) : null}
          <div className='text-base font-medium leading-loose'>
            <span ref={qrDescriptionRef}>{qrDescription}</span>
          </div>
        </div>
      </div>

      {currenciesImage || currenciesText ? (
        <div className='flex flex-row items-center gap-3'>
          {currenciesImage ? (
            <ImageWithPlaceholder {...currenciesImage} loading='lazy' />
          ) : null}

          {currenciesText ? (
            <div className='text-base font-medium leading-loose'>
              {currenciesText}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
