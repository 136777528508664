import { DEFAULT_LOCALE } from '@front/shared';
import {
  EActionType,
  TAction,
  toLinkProps,
  toImageProps,
  EButtonVariant,
  EButtonSize,
  EButtonTheme,
  EButtonIconPosition,
} from '@front/shared/ds';
import { Header } from '@shared/master-types';
import { useRouter } from 'next/router';
import { useCallback, useDebugValue, useMemo } from 'react';

import { useAppDispatch } from '../../store/store.hooks';
import { setCurrentModalId } from '../modals/modals.state';

type TMenuItems = Extract<
  NonNullable<Header['header']>[number],
  { blockType: 'header-simple' }
>['menuItems'];
type TMenuActions = NonNullable<Header['header']>[number]['menuActions'];

// Shared function to handle action type
const handleActionType = (
  action: TMenuItems[number]['action'],
  dispatch: ReturnType<typeof useAppDispatch>,
  router: ReturnType<typeof useRouter>,
): TAction => {
  const size = action?.size ? (action.size as EButtonSize) : undefined;
  const theme = action?.color ? (action.color as EButtonTheme) : undefined;
  const variant = action?.variant
    ? (action.variant as EButtonVariant)
    : undefined;

  if (action?.type === 'button') {
    return {
      type: EActionType.ButtonType,
      text: action.button?.text,
      dataGaId: action?.dataGaId,
      props: {
        variant: variant,
        size: size,
        theme: theme,
        children: action?.button?.text,
        ...(action.iconPosition === EButtonIconPosition.Left
          ? { leadingIcon: toImageProps(action?.icon) || undefined }
          : { trailingIcon: toImageProps(action?.icon) || undefined }),
        onClick: () => {
          if (
            action.button?.action === 'modal' &&
            action.button?.modal?.reference.value
          ) {
            return dispatch(
              setCurrentModalId({
                modalId: String(action.button?.modal?.reference.value),
              }),
            );
          }

          return undefined;
        },
      },
    };
  }

  if (action?.type === 'link') {
    return {
      type: EActionType.LinkType,
      text: action.link?.text,
      dataGaId: action?.dataGaId,
      props: {
        variant: variant,
        size: size,
        disabled: action?.link?.disabled,
        theme: theme,
        children: action?.link?.text,
        trailingIcon: toImageProps(action?.icon) || undefined,
        ...toLinkProps(
          String(router.query.locale || DEFAULT_LOCALE),
          action.link,
        ),
      },
    };
  }

  throw new Error(`Unsupported action type ${action?.type} detected`);
};

// Shared function to prepare actions
const prepareActions = (
  actions: TMenuItems | TMenuActions | undefined,
  dispatch: ReturnType<typeof useAppDispatch>,
  router: ReturnType<typeof useRouter>,
  handleActionTypeCallback: typeof handleActionType,
): TAction[] => {
  return (actions || [])
    .map(actionItem => {
      if ('type' in actionItem) {
        switch (actionItem.type) {
          case 'actions-group':
            if (
              actionItem &&
              'actionsGroup' in actionItem &&
              actionItem.actionsGroup
            ) {
              return {
                type: EActionType.LinksGroupType,
                label: actionItem.actionsGroup.label,
                actions: actionItem.actionsGroup.actions.map(actionGroupItem =>
                  handleActionTypeCallback(actionGroupItem, dispatch, router),
                ),
              };
            } else {
              return null;
            }
          case 'action':
            return handleActionTypeCallback(
              actionItem.action,
              dispatch,
              router,
            );
          case 'button':
          case 'link':
          default:
            return handleActionTypeCallback(actionItem, dispatch, router);
        }
      } else {
        return handleActionTypeCallback(actionItem, dispatch, router);
      }
    })
    .filter(Boolean) as TAction[];
};

// Existing hook: useActions
export const useActions = (
  actions: TMenuItems | TMenuActions | undefined = [],
): TAction[] => {
  const dispatch = useAppDispatch();
  const router = useRouter();

  const nextActions = useMemo<TAction[]>(() => {
    return prepareActions(actions, dispatch, router, handleActionType);
  }, [actions, dispatch, router]);

  useDebugValue(nextActions);

  return nextActions;
};

export const usePrepareActions = (): ((
  actions: TMenuItems | TMenuActions | undefined,
) => TAction[]) => {
  const dispatch = useAppDispatch();
  const router = useRouter();

  return useCallback(
    (actions: TMenuItems | TMenuActions | undefined = []): TAction[] => {
      return prepareActions(actions, dispatch, router, handleActionType);
    },
    [dispatch, router],
  );
};
