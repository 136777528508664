import { getGaId } from '@front/shared';
import clsx from 'clsx';
import { ImageProps } from 'next/image';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import {
  EHeroSectionContentWidth,
  EHeroSectionTitlePosition,
  EHeroSectionTitleTheme,
  EHeroSectionTitleType,
  eyebrowsCVA,
  heroTitleCVA,
  descriptionCVA,
} from './HeroSectionTitle.constants';
import Buttons from './components/Buttons';
import { TActionWithText, EActionType } from '../../../../src/types';
import { Button, EButtonSize, EButtonTheme } from '../../atoms/Button';
import {
  Eyebrow,
  EEyebrowVariant,
  EEyebrowSize,
  EEyebrowTheme,
} from '../../atoms/Eyebrow';
import { ESimpleIconSize, Icon } from '../../atoms/Icon';

export type THeroSectionTitleProps = {
  topImage?: ImageProps | null;
  title: React.JSX.Element | string;
  description?: React.JSX.Element | string;
  actions?: TActionWithText[];
  eyebrowsBelow?: string[];
  eyebrowsAbove?: string[];
  position?: EHeroSectionTitlePosition;
  type?: EHeroSectionTitleType;
  theme?: EHeroSectionTitleTheme;
  contentWidth?: EHeroSectionContentWidth;
  className?: string;
  actionsClassName?: string;
};

export const HeroSectionTitle: React.FC<THeroSectionTitleProps> = ({
  topImage,
  title,
  description,
  eyebrowsBelow,
  eyebrowsAbove,
  actions = [],
  position = EHeroSectionTitlePosition.Center,
  theme = EHeroSectionTitleTheme.Theme1,
  type = EHeroSectionTitleType.A,
  contentWidth = EHeroSectionContentWidth.Md,
  className,
  actionsClassName,
}) => {
  let eyebrowTheme = EEyebrowTheme.Primary;
  let eyebrowVariant = EEyebrowVariant.Text;
  let eyebrowSize = EEyebrowSize.Large;
  let buttonTheme = EButtonTheme.Primary;

  if (theme === EHeroSectionTitleTheme.Theme5) {
    eyebrowTheme = EEyebrowTheme.Light;
    buttonTheme = EButtonTheme.SecondaryOnColour;
  }

  if (type === EHeroSectionTitleType.B) {
    eyebrowVariant = EEyebrowVariant.TextTracking;
    eyebrowSize = EEyebrowSize.Medium;
    buttonTheme = EButtonTheme.Tertiary;
  }

  return (
    <div
      className={clsx('grid w-full gap-8 lg:mt-0 xl:gap-12', className, {
        'xl:max-w-[38rem]': contentWidth === EHeroSectionContentWidth.Sm,
        'xl:max-w-[38.75rem]': contentWidth === EHeroSectionContentWidth.Md,
        'xl:max-w-[52rem]': contentWidth === EHeroSectionContentWidth.Lg,
        'xl:max-w-[64rem]': contentWidth === EHeroSectionContentWidth.Xl,
        'mx-auto': position === EHeroSectionTitlePosition.Center,
      })}
    >
      <div
        className={clsx(
          'flex flex-col',
          position === EHeroSectionTitlePosition.Center
            ? 'mx-auto text-center'
            : 'text-start',
          type === EHeroSectionTitleType.B ? 'gap-6' : 'gap-3 md:gap-4',
        )}
      >
        {topImage && (
          <Icon
            size={ESimpleIconSize.XxL}
            icon={topImage}
            className={clsx({
              'pb-3 md:pb-2': type === EHeroSectionTitleType.A,
              'mx-auto': position === EHeroSectionTitlePosition.Center,
            })}
          />
        )}
        {!!eyebrowsAbove?.length && (
          <div
            className={clsx(
              'flex flex-row flex-wrap',
              type === EHeroSectionTitleType.B ? 'gap-6' : 'gap-3',
              {
                'justify-center': position === EHeroSectionTitlePosition.Center,
              },
            )}
          >
            {eyebrowsAbove.map((eyebrow, index) => (
              <Eyebrow
                key={eyebrow + index}
                variant={eyebrowVariant}
                size={eyebrowSize}
                theme={eyebrowTheme}
                className={eyebrowsCVA({ theme: theme, type: type })}
              >
                {eyebrow}
              </Eyebrow>
            ))}
          </div>
        )}
        <div
          className={clsx(
            'flex flex-col',
            type === EHeroSectionTitleType.B
              ? 'gap-6 lg:gap-8'
              : 'gap-3 md:gap-4',
          )}
        >
          <h1
            className={twMerge(
              clsx('whitespace-pre-line', {
                'text-interface-1000': theme === EHeroSectionTitleTheme.Theme1,
                'text-interface-50':
                  theme === EHeroSectionTitleTheme.Theme4 ||
                  theme === EHeroSectionTitleTheme.Theme5 ||
                  theme === EHeroSectionTitleTheme.HeroSectionB3,
                'text-7xl font-semibold leading-snug xl:text-8xl xl:leading-tight 2xl:text-9xl':
                  type === EHeroSectionTitleType.A,
                'text-7xl leading-snug xl:text-10xl xl:leading-normal':
                  type === EHeroSectionTitleType.B,
              }),
              heroTitleCVA({ theme: theme, type: type }),
            )}
          >
            {title}
          </h1>

          {!!eyebrowsBelow?.length && (
            <div
              className={clsx(
                'flex flex-row flex-wrap',
                type === EHeroSectionTitleType.B ? 'gap-6' : 'gap-3',
                {
                  'justify-center':
                    position === EHeroSectionTitlePosition.Center,
                },
              )}
            >
              {eyebrowsBelow.map((eyebrow, index) => (
                <Eyebrow
                  key={eyebrow + index}
                  variant={eyebrowVariant}
                  size={eyebrowSize}
                  theme={eyebrowTheme}
                  className={eyebrowsCVA({ theme: theme, type: type })}
                >
                  {eyebrow}
                </Eyebrow>
              ))}
            </div>
          )}

          {description && (
            <h2
              className={twMerge(
                clsx(
                  'font-regular whitespace-pre-line text-base leading-extra-loose xl:max-w-[38.75rem]',
                  {
                    'text-interface-800':
                      theme === EHeroSectionTitleTheme.Theme1,
                    'text-interface-50/80':
                      theme === EHeroSectionTitleTheme.Theme4 ||
                      theme === EHeroSectionTitleTheme.Theme5 ||
                      theme === EHeroSectionTitleTheme.HeroSectionB3,
                    'xl:mx-auto': position === EHeroSectionTitlePosition.Center,
                  },
                ),
                descriptionCVA({ theme: theme, type: type }),
              )}
            >
              {description}
            </h2>
          )}
        </div>
      </div>

      {actions.length ? (
        type === EHeroSectionTitleType.A ? (
          <Buttons
            actions={actions}
            alignCenter={position === EHeroSectionTitlePosition.Center}
            className={'flex flex-wrap gap-8'}
          />
        ) : (
          <div
            className={clsx(
              'flex w-full flex-row flex-wrap items-center gap-4 2xl:max-w-[50rem]',
              actionsClassName,
              {
                'mx-auto justify-center':
                  position === EHeroSectionTitlePosition.Center,
              },
            )}
          >
            {actions.map((action, index) => (
              <React.Fragment key={index}>
                {'props' in action && (
                  <Button
                    theme={buttonTheme}
                    size={EButtonSize.Large}
                    {...action.props}
                    data-ga-id={getGaId(action.dataGaId || action.text, 'hero')}
                  />
                )}
                {action.type === EActionType.Text && (
                  <div className='px-4 text-lg font-medium leading-normal text-interface-500'>
                    {action.text}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        )
      ) : null}
    </div>
  );
};

export * from './HeroSectionTitle.constants';
