import { useAutoAnimate } from '@formkit/auto-animate/react';
import clsx from 'clsx';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import { EInputRounded, EInputVariant } from './Input.constants';

export interface IInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  className?: string;
  rounded?: EInputRounded;
  variant?: EInputVariant;
  error?: string;
  prependIconName?: string;
  appendIconName?: string;
  fullWidth?: boolean;
  showRequiredSymbol?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, IInputProps>((props, ref) => {
  const {
    variant = EInputVariant.Primary,
    showRequiredSymbol = true,
    rounded,
    placeholder,
    required,
    id,
    error,
    fullWidth,
    className,
    ...restProps
  } = props;

  const [parent] = useAutoAnimate<HTMLDivElement>();
  const [wrapper] = useAutoAnimate<HTMLDivElement>();

  return (
    <div ref={parent} className='grid gap-2'>
      <div
        ref={wrapper}
        className={twMerge(
          clsx(
            'grid h-15 gap-1 px-4',
            variant === EInputVariant.Primary && 'bg-surface-100-input',
            variant === EInputVariant.Secondary && 'bg-surface-50-input',
            variant === EInputVariant.Outline && 'bg-white',
            rounded === EInputRounded.Full && 'rounded-full',
            !rounded && 'rounded-xl',
            variant === EInputVariant.Outline &&
              !!error &&
              'border-2 border-error',
            className,
          ),
        )}
      >
        <input
          id={id}
          ref={ref}
          {...restProps}
          placeholder={`${placeholder}${
            required && showRequiredSymbol ? '*' : ''
          }`}
          required={required}
          className={clsx(
            'peer/input bg-transparent p-0 text-base font-medium leading-relaxed caret-brand-500 focus:outline-none',
            variant === EInputVariant.Primary && 'text-interface-800',
            fullWidth && 'w-full',
          )}
        />
      </div>
      {error && (
        <label className='px-4 text-xs leading-loose text-error'>{error}</label>
      )}
    </div>
  );
});

Input.displayName = 'Input';

export default Input;
