import { addJurisdictionSupport, getGaId } from '@front/shared';
import {
  Container,
  EContainerVariant,
  FixedElement,
  HeroSectionTitle,
  EHeroSectionTitleTheme,
  EHeroSectionContentWidth,
} from '@front/shared/ds';
import { Hero, Locale } from '@shared/master-types';
import clsx from 'clsx';
import React, { ReactNode } from 'react';

import { HeroAnimatedSliderConnected } from './HeroAnimatedSlider';
import HeroVariantVideoV2 from './HeroVariantVideoV2';
import { useHeroImages } from './useHeroImages';
import { useActions } from '../../..';
import DefaultCardsConnected from '../DefaultCardsConnected';

export enum ESectionBackground {
  Transparent = 'transparent',
  Light = 'light',
}

export enum EHeroBackground {
  Primary = 'primary',
  Light = 'light',
  Dark = 'dark',
  Transparent = 'transparent',
}

export type THeroConnectedProps = {
  hero: Hero;
  locales?: Locale[];
  customImages?: ReactNode[];
  background?: EHeroBackground;
  contentWidth?: EHeroSectionContentWidth;
  sectionBackground?: ESectionBackground;
};

const HeroConnected: React.FC<THeroConnectedProps> = ({
  hero,
  locales,
  customImages,
}) => {
  const {
    title,
    contentWidth,
    subtitle,
    background = EHeroBackground.Light,
    sectionBackground = ESectionBackground.Transparent,
    sectionTitleTheme = EHeroSectionTitleTheme.Theme1,
    eyebrowsBottom,
    eyebrowsTop,
  } = hero;

  const heroActions = addJurisdictionSupport({
    jurisdictionList: [],
    localeList: locales,
    actionList: useActions(hero?.actions),
  });

  const isDarkPreloader = background === EHeroBackground.Dark;

  const fixedAction = useActions(hero?.fixedElement?.action);

  const { images } = useHeroImages({ hero, customImages, isDarkPreloader });

  const eyebrowsAbove = (eyebrowsTop || []).map(eyebrow => eyebrow.text);
  const eyebrowsBelow = (eyebrowsBottom || []).map(eyebrow => eyebrow.text);

  if (hero?.variant === 'AnimatedHeroSlider') {
    return <HeroAnimatedSliderConnected hero={hero} />;
  }

  if (hero?.variant === 'VideoV2') {
    return (
      <HeroVariantVideoV2
        hero={hero}
        contentWidth={contentWidth as EHeroSectionContentWidth}
        eyebrowsAbove={eyebrowsAbove}
        eyebrowsBelow={eyebrowsBelow}
        fixedAction={fixedAction}
        heroActions={heroActions}
      />
    );
  }

  return (
    <div
      className={clsx('overflow-hidden pb-12 2xl:pb-16', {
        'bg-transparent': sectionBackground === ESectionBackground.Transparent,
        'bg-surface-100': sectionBackground === ESectionBackground.Light,
      })}
    >
      <Container
        variant={EContainerVariant.Lead}
        dataGaId={getGaId(hero.name || hero.title, 'hero')}
        isNoPaddingOnMobile
      >
        <div className='flex flex-col gap-4 xl:px-4 2xl:px-0'>
          <section
            className={clsx('relative ', {
              'flex min-h-[15.5rem] items-center justify-center py-[4.875rem] md:min-h-[30rem] md:py-0':
                hero && hero?.variant === 'CoverImage',
            })}
          >
            {!!images?.length && (
              <div className='pointer-events-none absolute inset-0 overflow-hidden rounded-3xl'>
                {images}
              </div>
            )}

            <div>
              {fixedAction?.map((action, index) => (
                <FixedElement key={index} action={action} />
              ))}
              <div
                className={clsx(
                  'grid items-center rounded-3xl bg-brand-100 p-4 xl:h-[31.25rem] xl:p-8',
                  {
                    'bg-brand-500': background === EHeroBackground.Primary,
                    'bg-surface-1000': background === EHeroBackground.Dark,
                    'bg-surface-100': background === EHeroBackground.Light,
                    'bg-transparent':
                      background === EHeroBackground.Transparent,
                  },
                  hero && hero?.variant === 'ThreeImages' && 'pt-[10.938rem]',
                )}
              >
                <HeroSectionTitle
                  contentWidth={contentWidth as EHeroSectionContentWidth}
                  title={title}
                  description={subtitle}
                  eyebrowsAbove={eyebrowsAbove}
                  eyebrowsBelow={eyebrowsBelow}
                  actions={heroActions}
                  theme={sectionTitleTheme as EHeroSectionTitleTheme}
                  className='z-10'
                />
              </div>
            </div>
          </section>
          {hero.cardBlocks?.map(row => (
            <DefaultCardsConnected key={row.id} {...row} />
          ))}
        </div>
      </Container>
    </div>
  );
};
export default HeroConnected;
