import {
  isRichTextContentEmpty,
  RichText,
  TAction,
  toImageProps,
} from '@front/shared/ds';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

import { HeroAnimatedSlider } from './HeroAnimatedSlider';
import { useAppDispatch } from '../../../../store/store.hooks';
import prepareCTA from '../../../../utils/prepareCTA';

import type { TSliderAnimation } from './types';
import type { Hero } from '@shared/master-types';

type THeroAnimatedSliderConnectedProps = {
  hero: Hero;
};

export const HeroAnimatedSliderConnected: React.FC<
  THeroAnimatedSliderConnectedProps
> = ({ hero }) => {
  const { animatedSlider } = hero;
  const appDispatch = useAppDispatch();
  const router = useRouter();
  const currentLocale = String(router.query.locale || '');

  const { showSlider } = animatedSlider || {};
  const isShowSlider = showSlider === 'Yes';

  const sliderCards: React.ComponentProps<
    typeof HeroAnimatedSlider
  >['sliderCards'] = useMemo(() => {
    return (animatedSlider?.sliderCards || []).map(
      ({
        sliderTitle,
        sliderIcon,
        mediaType,
        video,
        image,
        eyebrowsTop,
        eyebrowsBottom,
        title,
        description,
        animation: animations,
        cta,
        visualMaskLayer,
      }) => {
        const preparedActions: TAction[] = prepareCTA(
          cta,
          currentLocale,
          appDispatch,
        );

        const preparedAnimations = (animations || [])
          .map(animation => {
            if (animation.blockType === 'qr') {
              return {
                type: 'qr',
                data: {
                  order: animation.order,
                  type: animation.type,
                  price: animation.price,
                  qrImage: toImageProps(animation.qrImage),
                  qrDescription: animation.qrDescription,
                  currenciesImage: toImageProps(animation.currenciesImage),
                  currenciesText: animation.currenciesText,
                },
              } as TSliderAnimation;
            }

            return undefined;
          })
          .filter(Boolean);

        const preparedAnimation = preparedAnimations[0];

        return {
          sliderTitle,
          sliderIcon: toImageProps(sliderIcon),
          image: mediaType === 'Image' ? toImageProps(image) : null,
          video: mediaType === 'Video' ? video : null,
          eyebrowsTop: (eyebrowsTop || []).map(data => data.text),
          eyebrowsBottom: (eyebrowsBottom || []).map(data => data.text),
          title: {
            lineBeforeAnimation:
              title?.lineBeforeAnimation &&
              !isRichTextContentEmpty(title.lineBeforeAnimation) ? (
                <RichText content={title.lineBeforeAnimation} />
              ) : null,
            lineWithAnimation: title?.lineWithAnimation,
            lineAfterAnimation:
              title?.lineAfterAnimation &&
              !isRichTextContentEmpty(title.lineAfterAnimation) ? (
                <RichText content={title.lineAfterAnimation} />
              ) : null,
          },
          description:
            description && !isRichTextContentEmpty(description) ? (
              <RichText content={description} />
            ) : null,
          actions: preparedActions,
          animation: preparedAnimation,
          isVisualMaskLayer: visualMaskLayer === 'Yes',
        };
      },
    );
  }, [animatedSlider?.sliderCards, appDispatch, currentLocale]);

  return (
    <HeroAnimatedSlider sliderCards={sliderCards} isShowSlider={isShowSlider} />
  );
};
