export enum ERichTextBulletsTheme {
  Theme1 = 'theme_1',
  Theme2 = 'theme_2',
}

export enum ERichTextBullets {
  CheckMark = 'checkMark',
  Rocket = 'rocket',
  CircleMark = 'circleMark',
}
