import { ImageWithPlaceholder } from '@front/shared/ds';
import React, { useEffect, useRef } from 'react';
import { EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';

import { Mask } from './Mask';
import { VideoResponsive } from '../../../common';

import type { Media } from '@shared/master-types';
import type { ImageProps } from 'next/image';

type TMediaSlide = {
  image?: ImageProps | null;
  video?: Media | string | null;
  hasMask?: boolean;
};

type TMediaSlidesProps = {
  slides: TMediaSlide[];
  activeIndex: number;
};

export const MediaSlides: React.FC<TMediaSlidesProps> = ({
  slides,
  activeIndex,
}) => {
  const sliderRef = useRef<SwiperRef>(null);

  useEffect(() => {
    sliderRef.current?.swiper.slideTo(activeIndex);
  }, [activeIndex]);

  return (
    <Swiper
      ref={sliderRef}
      className='absolute left-0 top-0 h-full w-full'
      modules={[EffectFade]}
      effect='fade'
      allowTouchMove={false}
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          {slide.video ? (
            <div className='absolute bottom-0 left-0 h-full w-full'>
              <VideoResponsive
                loop
                playsInline
                muted
                autoPlay
                video={slide.video}
                className='h-full w-full object-cover object-top'
                preload='none'
              />
            </div>
          ) : null}

          {slide.image ? (
            <div className='absolute bottom-0 left-0 h-full w-full'>
              <ImageWithPlaceholder
                {...slide.image}
                loading='lazy'
                className='h-full w-full object-cover'
              />
            </div>
          ) : null}

          {slide.hasMask ? <Mask /> : null}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
