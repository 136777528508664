import { cva } from 'cva';
import React, { ReactNode } from 'react';

import {
  ETitlePartsPosition,
  ETitlePartsSize,
  ETitlePartsTheme,
} from './TitlePart.constants';

export const titlePartsCVA = cva('w-full', {
  variants: {
    position: {
      [ETitlePartsPosition.Center]: 'text-center',
      [ETitlePartsPosition.Left]: 'text-start',
    },
    size: {
      [ETitlePartsSize.XxxxxxL]: 'text-10xl font-bold leading-normal',
      [ETitlePartsSize.XxxxxL]: 'text-9xl font-bold leading-tight',
      [ETitlePartsSize.XxxxL]: 'text-8xl font-bold leading-relaxed',
      [ETitlePartsSize.XxxL]: 'text-8xl font-semibold leading-snug',
      [ETitlePartsSize.XxL]: 'text-7xl font-semibold leading-snug',
      [ETitlePartsSize.Xl]: 'text-5xl font-semibold leading-loose',
      [ETitlePartsSize.L]: 'text-4xl font-semibold leading-relaxed',
      [ETitlePartsSize.Ml]: 'text-3xl font-semibold leading-loose',
      [ETitlePartsSize.M]: 'text-2xl font-semibold leading-extra-loose',
      [ETitlePartsSize.S]: 'text-xl font-semibold leading-extra-loose',
      [ETitlePartsSize.Xs]: 'text-base font-semibold leading-extra-loose',
    },
    theme: {
      [ETitlePartsTheme.Primary]: 'text-brand-500',
      [ETitlePartsTheme.Light]: 'text-interface-50',
      [ETitlePartsTheme.Dark]: 'text-interface-1000',
    },
  },
  compoundVariants: [],
});

export type TTitlePartsProps = {
  children: ReactNode;
  size: ETitlePartsSize;
  position?: ETitlePartsPosition;
  theme?: ETitlePartsTheme;
  tag?: keyof React.JSX.IntrinsicElements;
  className?: string;
};

export const TitlePart: React.FC<TTitlePartsProps> = props => {
  const {
    children,
    position = ETitlePartsPosition.Left,
    size,
    theme = ETitlePartsTheme.Dark,
    tag,
    className,
  } = props;

  const Tag = tag || 'div';

  return (
    <Tag className={titlePartsCVA({ position, size, theme, className })}>
      {children}
    </Tag>
  );
};
