import {
  EHeroSectionTitlePosition,
  EHeroSectionTitleTheme,
  EHeroSectionTitleType,
  HeroSectionTitle,
  useScramble,
} from '@front/shared/ds';
import React from 'react';

import { scrambleSettings } from './constants';

import type { TSliderCard } from './types';

type THeroAnimatedSliderTitleProps = {
  activeSlide: TSliderCard;
  isXlBreakpoint?: boolean;
};

export const HeroAnimatedSliderTitle: React.FC<
  THeroAnimatedSliderTitleProps
> = ({ activeSlide, isXlBreakpoint }) => {
  const { title } = activeSlide;

  const hasHeroSectionTitle =
    activeSlide.title ||
    activeSlide.description ||
    activeSlide.eyebrowsTop?.length ||
    activeSlide.eyebrowsBottom?.length ||
    activeSlide.actions?.length;

  const { ref: titleAnimationRef } = useScramble({
    text: activeSlide?.title?.lineWithAnimation || '',
    ...scrambleSettings,
  });

  if (!hasHeroSectionTitle) {
    return null;
  }

  const hasTitleParts =
    title?.lineBeforeAnimation ||
    title?.lineWithAnimation ||
    title?.lineAfterAnimation;

  const heroSectionTitle = hasTitleParts ? (
    <>
      {title?.lineBeforeAnimation}
      {title?.lineWithAnimation && isXlBreakpoint ? (
        <b ref={titleAnimationRef} />
      ) : (
        <b>{title?.lineWithAnimation}</b>
      )}
      {title?.lineAfterAnimation}
    </>
  ) : null;

  return (
    <div className='xl:pe-[32px]'>
      <HeroSectionTitle
        title={heroSectionTitle || ''}
        description={activeSlide.description || ''}
        eyebrowsAbove={activeSlide.eyebrowsTop}
        eyebrowsBelow={activeSlide.eyebrowsBottom}
        actions={activeSlide.actions}
        position={
          isXlBreakpoint
            ? EHeroSectionTitlePosition.Left
            : EHeroSectionTitlePosition.Center
        }
        theme={EHeroSectionTitleTheme.HeroSectionB3}
        type={EHeroSectionTitleType.B}
      />
    </div>
  );
};
