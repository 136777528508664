import { EModalBackground, EModalPadding, Modal } from '@front/shared/ds';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import React, { MouseEvent, useEffect, useRef, FC } from 'react';

import { EModalsRootType } from './ModalsRoot.constants';
import { useGetModalQuery } from '../../../features/modals/getModal.query';
import { useModalsControl } from '../../../features/modals/useModalsControl.hook';
import { useLocale } from '../../../hooks';
import { EFormInputParserVariant } from '../../sections/FormInputParser';

const ModalForm = dynamic(() =>
  import(/* webpackChunkName: "ModalForm" */ './ModalForm').then(
    r => r.ModalForm,
  ),
);

const ModalMultistepForm = dynamic(() =>
  import(
    /* webpackChunkName: "ModalMultistepForm" */ './ModalMultistepForm'
  ).then(r => r.ModalMultistepForm),
);

const ModalsRoot: FC = () => {
  const { locale } = useLocale();
  const { currentModalId, closeModal } = useModalsControl();
  const { data: modal } = useGetModalQuery(
    { id: String(currentModalId), locale },
    { skip: !currentModalId },
  );

  const modalRef = useRef<HTMLDialogElement>(null);
  const modalFormType = typeof modal?.form !== 'string' && modal?.form?.type;
  const modalBackground = modal?.background as EModalBackground;
  const modalPadding =
    modalFormType === EModalsRootType.Multistep
      ? EModalPadding.Medium
      : EModalPadding.Large;

  useEffect(() => {
    if (modalRef.current) {
      if (currentModalId && modal && !modalRef.current.open) {
        modalRef.current.showModal();
      }
    }
  }, [currentModalId, modal]);

  const handleCloseModal = (event: MouseEvent<HTMLDialogElement>): void => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  let inputTheme = EFormInputParserVariant.Secondary;
  if (modalBackground === EModalBackground.White) {
    inputTheme = EFormInputParserVariant.Primary;
  }

  const scrollBarClasses =
    'scrollbar scrollbar-w-1 scrollbar-thumb-interface-500 scrollbar-track-control-200 scroll-smooth';

  return modal && currentModalId ? (
    <dialog
      ref={modalRef}
      className={clsx(
        'w-full max-w-[100vw] bg-transparent p-0 outline-none backdrop:bg-interface-1000/70',
        scrollBarClasses,
        {
          'lg:max-w-[28rem]':
            modalFormType === EModalsRootType.Single ||
            (!modal.form && !modal.fullscreen),
          'lg:max-w-[540px] 2xl:px-4':
            modalFormType === EModalsRootType.Multistep,
        },
      )}
      onClose={closeModal}
      onClick={handleCloseModal}
    >
      <Modal
        title={modal.title}
        onClose={closeModal}
        padding={modalPadding}
        modalBackground={modalBackground}
      >
        {modal.form && typeof modal.form !== 'string' && (
          <>
            {modal.form.type === EModalsRootType.Single && (
              <ModalForm
                form={modal.form}
                additionalText={modal.additionalText}
                onSubmit={closeModal}
                inputTheme={inputTheme}
              />
            )}
            {modal.form.type === EModalsRootType.Multistep && (
              <ModalMultistepForm
                form={modal.form}
                additionalText={modal.additionalText}
                onSubmit={closeModal}
                inputTheme={inputTheme}
              />
            )}
          </>
        )}
      </Modal>
    </dialog>
  ) : null;
};

export default ModalsRoot;
