import { VideoCollection } from '@shared/master-types';
import clsx from 'clsx';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import YouTube, { YouTubePlayer } from 'react-youtube';

import { EVideoSize } from './Video.constants';
import { ReactComponent as IconYoutubePlayButton } from '../../../../public/icons/youtube-play-button.svg';
import toImageProps from '../../../utils/toImageProps';
import { TYouTubePlayer } from '../../rows';
import { ImageWithPlaceholder } from '../ImageWithPlaceholder';
import { RichText, TRichTextContent } from '../RichText';

export type TVideoProps = {
  video: VideoCollection | string;
  text?: TRichTextContent;
  size?: EVideoSize;
  className?: string;
  hasCover?: boolean;
  pauseAllVideos?: (videoId: string) => void;
};

export const Video = forwardRef<TYouTubePlayer, TVideoProps>((props, ref) => {
  const {
    video,
    text,
    className,
    size = EVideoSize.L,
    hasCover = true,
    pauseAllVideos,
  } = props;

  const hasVideo = typeof video !== 'string';
  const hasVideoCover = hasVideo && typeof video.cover !== 'string';
  const imageWithProps = hasVideoCover && toImageProps(video.cover);
  const [coverPreload, setCoverPreload] = useState(hasCover);

  useEffect(() => {
    setCoverPreload(hasCover);
  }, [hasCover]);

  const videoClickEvent = (): void => {
    setCoverPreload(false);
  };

  const youtubeRef = useRef<TYouTubePlayer | null>(null);

  useImperativeHandle(ref, () => {
    return {
      pause() {
        if (youtubeRef.current) {
          (youtubeRef.current as YouTubePlayer).pauseVideo();
        }
      },
      videoId: (video as VideoCollection).videoID,
    };
  }, [video]);

  return (
    <div className={className}>
      {hasVideo && (
        <div className='relative pt-[56.29%]'>
          {!coverPreload && (
            <YouTube
              onReady={event => {
                if (youtubeRef) {
                  youtubeRef.current = event.target;
                }
              }}
              iframeClassName={clsx(
                'absolute inset-0 top-0 aspect-video h-full w-full rounded-[1.25rem]',
                {
                  'sm:rounded-[2rem]':
                    size === EVideoSize.Xl || size === EVideoSize.L,
                },
              )}
              videoId={video.videoID as string}
              opts={{
                playerVars: {
                  autoplay: 1,
                },
              }}
              onPlay={() => {
                if (pauseAllVideos) {
                  pauseAllVideos(video.videoID);
                }
              }}
            />
          )}
          {coverPreload && (
            <div
              onClick={videoClickEvent}
              className={clsx(
                'absolute inset-0 top-0 h-full w-full cursor-pointer overflow-hidden rounded-[1.25rem] prose-img:h-full prose-img:w-full prose-img:object-cover',
                {
                  'sm:rounded-[2rem]':
                    size === EVideoSize.Xl || size === EVideoSize.L,
                },
              )}
            >
              <div className='absolute h-full w-full bg-[#0F131C4D]/30'></div>
              <div
                className={clsx(
                  'absolute inset-0 m-auto h-[2.188rem] w-[3.125rem] opacity-60 hover:opacity-100',
                  {
                    'sm:h-[4.3125rem] sm:w-[6.25rem]': size === EVideoSize.Xl,
                    'sm:h-[4.3125rem] sm:w-[6.25rem] lg:h-[2.188rem] lg:w-[3.125rem] xl:h-[4.3125rem] xl:w-[6.25rem]':
                      size === EVideoSize.L,
                    '2xl:h-[3.25rem] 2xl:w-[4.688rem]': size === EVideoSize.M,
                  },
                )}
              >
                <IconYoutubePlayButton />
              </div>
              {imageWithProps && (
                <ImageWithPlaceholder
                  {...imageWithProps}
                  alt={video.title}
                  itemProp='image'
                  className='pointer-events-none w-full'
                />
              )}
              <div className='absolute bottom-4 end-6 text-sm text-interface-50'>
                {video.time}
              </div>
            </div>
          )}
        </div>
      )}
      {!!text && (
        <div
          className={clsx(
            'break-words pt-2',
            'prose-a:text-brand-500 prose-a:transition hover:prose-a:text-brand-550',
            'prose-p:text-xs prose-p:leading-loose prose-p:text-interface-500',
          )}
        >
          <RichText content={text} />
        </div>
      )}
    </div>
  );
});
