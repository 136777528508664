import { EActionType, TAction } from '@front/shared/ds';
import { Section } from '@shared/master-types';

import toCardButtons from './toCardButtons';
import { TAppDispatch } from '../store/store.types';

export default function prepareCTA(
  cta: Extract<
    Required<Section>['rows'][number],
    { blockType: 'hero-section-title-row' }
  >['cta'],
  locale: string,
  dispatch: TAppDispatch,
): TAction[] {
  return (cta || [])
    .map(action => {
      if (action.blockType === 'button') {
        const cardButtons = toCardButtons([action], locale, dispatch);

        return cardButtons?.length
          ? ({
              type: EActionType.ButtonType,
              props: cardButtons?.[0],
            } as TAction)
          : undefined;
      }

      if (action.blockType === 'description') {
        return action.content
          ? { type: EActionType.Text, text: action.content }
          : undefined;
      }

      return undefined;
    })
    .filter((res): res is TAction => !!res);
}
