import { Button, RichText } from '@front/shared/ds';
import React, { useEffect, useRef, useState } from 'react';

import { useGetSettingsQuery } from '../../../features';
import { useLocale } from '../../../hooks';
import { isSSR } from '../../../utils';

export const CookieBanner: React.FC = () => {
  const [showBanner, setShowBanner] = useState(false);

  const { locale } = useLocale();
  const bannerRef = useRef<HTMLDivElement | null>(null);
  const [leftPosition, setLeftPosition] = useState<string | undefined>();

  const { data: settings } = useGetSettingsQuery({ locale });

  const cookieBannerEnabled =
    !isSSR() &&
    settings?.cookieBanner &&
    !localStorage.getItem(settings?.cookieBanner.buttonText) &&
    settings?.cookieBanner.bodyText &&
    settings?.cookieBanner.buttonText &&
    settings?.cookieBanner.shouldShow;

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (!showBanner && cookieBannerEnabled) {
      timeout = setTimeout(() => {
        setShowBanner(true);
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookieBannerEnabled]);

  const calculateLeftPosition = (): void => {
    if (bannerRef.current) {
      const bannerWidth = bannerRef.current.offsetWidth;
      const windowWidth = window.innerWidth;
      const calculatedLeft = (windowWidth - bannerWidth) / 2;
      setLeftPosition(`${calculatedLeft}px`);
    }
  };

  useEffect(() => {
    if (showBanner) {
      calculateLeftPosition();
      window.addEventListener('resize', calculateLeftPosition);
    }

    return () => window.removeEventListener('resize', calculateLeftPosition);
  }, [showBanner]);

  if (!cookieBannerEnabled || !showBanner) {
    return null;
  }

  const { cookieBanner } = settings;

  const onClose = (): void => {
    setShowBanner(false);
    localStorage.setItem(cookieBanner.buttonText, JSON.stringify(true));
  };

  return (
    <div
      ref={bannerRef}
      style={{ left: leftPosition }}
      className='fixed bottom-0 z-[100] flex w-full max-w-[22.438rem] flex-col items-center gap-4 rounded-xl bg-surface-50-tooltip/30 p-4 text-center text-sm leading-loose text-interface-1000 backdrop-blur-xl lg:bottom-2 lg:max-w-[33.75rem] lg:flex-row lg:justify-between lg:px-5 lg:py-4 lg:text-left xl:bottom-10 xl:px-6 xl:py-5'
      role='dialog'
    >
      <RichText content={cookieBanner.bodyText} />
      <Button
        className='w-full lg:w-auto lg:whitespace-nowrap'
        onClick={onClose}
      >
        {cookieBanner.buttonText}
      </Button>
    </div>
  );
};
