import React from 'react';

import Serialize from './Serialize';
import { ERichTextBullets, ERichTextBulletsTheme } from './constants';
import { TLeaf } from './types';
import isRichTextContentEmpty from './utils/checkEmptyRichText';
import { processRichText } from './utils/processRichText';
import updateNewTabInContentRichText from './utils/updateNewTabInContentRichText';

export type TRichTextContent = { [k: string]: unknown }[];

export type TRichTextBulletsOptions = {
  bullets?: `${ERichTextBullets}`;
  bulletsTheme?: `${ERichTextBulletsTheme}`;
};

export type TRichTextProps = {
  content?: TLeaf | TLeaf[] | TRichTextContent;
  isOpenInNewTab?: boolean;
  className?: string;
  bulletsOptions?: TRichTextBulletsOptions;
};

const RichText: React.FC<TRichTextProps> = props => {
  const {
    content,
    isOpenInNewTab,
    className = '',
    bulletsOptions = {
      bullets: ERichTextBullets.CircleMark,
      bulletsTheme: ERichTextBulletsTheme.Theme1,
    },
  } = props;

  const contentArray = processRichText(content);

  let updatedContent: TLeaf[];

  if (isOpenInNewTab) {
    updatedContent = updateNewTabInContentRichText(contentArray as TLeaf[]);
  } else {
    updatedContent = contentArray as TLeaf[];
  }

  const isEmptyContent = isRichTextContentEmpty(updatedContent);

  if (!updatedContent.length || isEmptyContent) {
    return null;
  }

  return (
    <div className={className}>
      {Serialize(updatedContent, {
        bullets: bulletsOptions.bullets,
        bulletsTheme: bulletsOptions.bulletsTheme,
      })}
    </div>
  );
};

export default RichText;
